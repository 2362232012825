import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Container, Row, Col } from '../../components/ui/Wrapper';
import SectionTitle from '../../components/ui/section-title';
import Button from '../../components/ui/button';
import BoxIcon from '../../components/box-icon/layout-five';
import { Wrapper, SectionBottom } from './styles';
import useSiteLocation from '../../hooks/useSiteLocation';

const Benefits = ({
  title,
  subtitle,
  itemsFilter,
  primaryAction,
  secondaryAction,
  sectionTitleStyle,
  buttonOneStyle,
  buttonTwoStyle,
}) => {
  const { buildPath } = useSiteLocation();
  const data = useStaticQuery(graphql`
    query {
      allContentfulBenefit {
        nodes {
          id
          slug
          title
          description {
            description
          }
          icon {
            fluid(quality: 100, toFormat: WEBP) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `);

  const benefits = data.allContentfulBenefit.nodes;

  const sortBenefits = (a, b) => {
    if (!itemsFilter || itemsFilter.length === 0) {
      return true;
    }
    return itemsFilter.indexOf(a.slug) - itemsFilter.indexOf(b.slug);
  };

  const filterBenefits = benefit => {
    if (!itemsFilter || itemsFilter.length === 0) {
      return true;
    }
    return itemsFilter.includes(benefit.slug);
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle {...sectionTitleStyle} subtitle={subtitle} title={title} />
          </Col>
        </Row>
        <Row>
          {benefits
            .sort(sortBenefits)
            .filter(filterBenefits)
            .map(item => {
              return (
                <Col lg={4} md={6} key={item.id}>
                  <BoxIcon id={item.id} title={item.title} desc={item.description.description} icon={item.icon} />
                </Col>
              );
            })}
        </Row>
        <Row>
          <Col lg={12}>
            <SectionBottom className="text-center">
              <Button
                as={Link}
                to={buildPath(primaryAction.link)}
                target="_blank"
                bgcolor={primaryAction.bgcolor}
                color={primaryAction.color}
                {...buttonOneStyle}
              >
                {primaryAction.label}
              </Button>
              {secondaryAction && (
                <Button as={Link} to={secondaryAction.link} target="_blank" {...buttonTwoStyle}>
                  {secondaryAction.label}
                </Button>
              )}
            </SectionBottom>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

Benefits.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  itemsFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
    })
  ).isRequired,
  primaryAction: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.object,
      bgcolor: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  secondaryAction: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.object,
    })
  ),
  sectionTitleStyle: PropTypes.object,
  buttonOneStyle: PropTypes.object,
  buttonTwoStyle: PropTypes.object,
};

Benefits.defaultProps = {
  subtitle: 'BENEFÍCIOS E DIFERENCIAIS',
  primaryAction: {
    label: 'QUERO RESERVAR MINHA VAGA',
    link: '/fale-conosco',
  },
  secondaryAction: null,
  sectionTitleStyle: {
    mb: '40px',
    responsive: {
      small: {
        mb: '30px',
      },
    },
  },
  buttonOneStyle: {
    m: '10px',
    hover: '2',
  },
  buttonTwoStyle: {
    m: '10px',
    varient: 'outlined',
  },
};

export default Benefits;
